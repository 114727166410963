import {initializeApp } from 'firebase/app';
import { getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
  } from 'firebase/auth';
import { getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs,
  } from 'firebase/firestore';
//Set up for Google Sign up to work
const firebaseConfig = {
  apiKey: "AIzaSyC8v5z0v46gk_axx5HfwXKJbx2hWp_4YS4",
  authDomain: "anime-apparel-shop-db.firebaseapp.com",
  projectId: "anime-apparel-shop-db",
  storageBucket: "anime-apparel-shop-db.appspot.com",
  messagingSenderId: "248463045081",
  appId: "1:248463045081:web:ad8192ddcb5f1caa8829c8"
};

const firebaseApp = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters ({
  prompt: "select_account"
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signInWithGoogleRedirect = () =>  signInWithRedirect(auth, provider);
//Beginning of Code that is necessary for any data to go back to the DB
export const db = getFirestore();

export const addCollectionAndDoc = async(collectionKey, objectsToAdd) => {
  const collectionRef = collection (db, collectionKey);
  const batch = writeBatch(db);

  objectsToAdd.forEach((object)=> {
    const docRef = doc(collectionRef,object.title.toLowerCase());
    batch.set(docRef, object);
  });
  await batch.commit();
  console.log('done');
};

export const getCategoriesAndDoc = async () => {
  const collectionRef = collection(db, 'categories');
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);
  const categoryMap = querySnapshot.docs.reduce((acc, docSnapshot) => {
    const {title, items} = docSnapshot.data();
    acc[title.toLowerCase()] = items;
    return acc;
  }, {});
  return categoryMap; 
};


export const createUserDocumentFromAuth = async (userAuth, additionalInformation = { } ) => {
  if (!userAuth)return;
  const userDocRef = doc(db, 'users', userAuth.uid);

  const userSnapshot = await getDoc(userDocRef);
// Below it checks if the userSnapshot exist if it doesnt it will return true
//If  true then the rest of the code block will run and then it will create a user
// to be put into the DB
   if (!userSnapshot.exists()) {
     const { displayName, email } = userAuth;
     const createdAt = new Date();

     try {
       await setDoc(userDocRef, {
         displayName,
         email,
         createdAt,
         ...additionalInformation,
       });
     } catch (error) {
       console.log('error creating the user', error.message);
     }
   }

   return userDocRef;
 };

 export const createAuthUserWithEmailAndPassword = async (email,password) => {
   if(!email || !password)return;
   return await createUserWithEmailAndPassword(auth, email,password);
 };
 export const signInAuthUserWithEmailAndPassword = async (email,password) => {
   if(!email || !password)return;
   return await signInWithEmailAndPassword(auth, email,password);
 };
export const signOutUser = async() => await signOut(auth);
export const onAuthStateChangedListener = (callback) =>
{

  onAuthStateChanged(auth, callback)
};
